/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
// import PropTypes from "prop-types"

// plugins added by s4n
import { Link } from "gatsby-plugin-intl"
import LanguageSwitcher from '../../languageSwitcher'
// import { Location } from '@reach/router'
import { globalHistory } from "@reach/router"
import MenuUtils from "./utils"
import classNames from 'classnames';
import kebabCase from "lodash.kebabcase"

// @see: https://www.drupal.org/project/jsonapi_extras/issues/2942851



const MenuLevelGenerator = (props) => {
    const { data, level, ariaLabelledBy } = props;
    const { intl } = props;
    // const t = intl.formatMessage;

    // const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;
    // const path = globalHistory.location.pathname;

    const disableDropdown = props.disableDropdown || false;
    const enableLanguage = props.enableLanguage || false;

    // recursive menu tree generator
    // @see: https://stackoverflow.com/questions/45286713/render-recursively-a-nested-data-in-react
    // @see: https://www.drupal.org/project/jsonapi_extras/issues/2942851

    // const menuUtils = new MenuUtils(); we use static methods!

    const currentMenuItemFromRoute = MenuUtils.getCurrentMenuItemFromRoute();

    const classNameUlClass = classNames({
        "navbar-nav mr-auto": level === 0,
        [`level-${level}`]: level === 0,
        [props.className]: level === 0 && props.className,
        [`menu-type-${props.type}`]: level === 0 && !props.className,
        "dropdown-menu": !(level === 0) && !disableDropdown,
    });

    let propsUl = null;

    if (ariaLabelledBy) {
        propsUl = {
            "aria-labelledby": ariaLabelledBy,
        }
    }

    return (
        <ul className={classNameUlClass} {...propsUl}>
            {data.map(({ node }) => {
                //let nodeLinkUri = node.link.uri.replace(/:|\//g, '-');
                let nodeLinkUri = kebabCase(node.link.uri);
                let isActive = currentMenuItemFromRoute && currentMenuItemFromRoute.drupal_id === node.drupal_id ? true : false;
                let classNameLi = [

                    node.children.length && 0 < level ? 'dropdown-submenu' : null,

                    ariaLabelledBy ? 'dropdown-item' : 'nav-item',
                    0 === level ? !disableDropdown ? 
                        (node.children.length ? 
                            'dropdown' 
                            : (0 === level ? 
                                null 
                                : 'dropdown-item')) 
                        : null
                    : null,
                    isActive ? 'active' : null,
                    // @see: https://stackoverflow.com/questions/16576983/replace-multiple-characters-in-one-replace-call
                    nodeLinkUri,
                ],
                propsLi = {
                    
                },
                classNameA = [
                    node.children.length ? 'nav-link dropdown-toggle' : 'nav-link'
                ],
                propsA = null;

                if (node.children.length) {
                    propsA = {
                        "id": nodeLinkUri,

                        // "href": "#",
                        "role": "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                    };
                }

                return (
                    <li key={node.id} className={classNameLi.join(' ')}>
                        {/* {!!node.children.length ? 
                            // Bootstrap 4 requires href="#" for parent dropdown wrapper
                            <a 
                                className={classNameA.join(' ')}
                                {...propsA}
                            >
                                {node.title}
                            </a> : 
                            <Link 
                                to={node.link.url} 
                                className={classNameA.join(' ')}
                                {...propsA}
                            >
                                {node.title}
                            </Link>
                        } */}
                        <Link 
                            to={node.link.url} 
                            className={classNameA.join(' ')}
                            {...propsA}
                        >
                            {node.title}
                        </Link>

                        {!!node.children.length && <MenuLevelGenerator {...props} key={`key-nav-menu-type-${props.type}-level-${level}-node-id-${node.id}`} data={node.children} level={level + 1} ariaLabelledBy={nodeLinkUri} />}
                    </li>
                );
            })}

            {/* { console.log(currentMenuItemTranslations) } */}

            {/* Render languages only once for main nesting level */}
            {enableLanguage && 0 === level && <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />}
        </ul>
    );
}

export default MenuLevelGenerator