// import { Link } from "gatsby"
import React from "react"
// import PropTypes from "prop-types"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Team from './team'
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"



// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss



const ComponentFunctional = (props) => {
    const { children, name, nodeId, productId } = props

    const { intl } = props
    // const t = intl.formatMessage;

    // console.log(props.location)
    // const path = globalHistory.location.pathname;

    const translation = {
        form_contact: {
            pl: `Formularz kontaktowy`,
            en: `Contact form`,
            de: `Kontakt Formular`,
            es: `Formulario de contacto`,
        },
        address: {
            pl: `Adres`,
            en: `Address`,
            de: `Adresse`,
            es: `DIRECCIÓN`,
        },
    }

    // Loadable components START
    let 
        // UseDrupalBlockContentBasic = null,
        FormContactS4n = null,
        Harmonogram = null,
        Team = null;

    switch (nodeId) {
        case 5:
            // UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`), {fallback: <Preloader />})
            FormContactS4n = loadable(() => import(`./../forms/webform/contact_s4n`), {fallback: <Preloader />})
            break;
        case 871:
            Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`), {fallback: <Preloader />})
            break;
        case 873:
            Team = loadable(() => import(`./team`), {fallback: <Preloader />})
            break;
    }
    // const FormContactS4n = loadable(() => import(`./../forms/webform/contact_s4n`))
    // const UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`))
    // const Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`))
    // const Team = loadable(() => import(`./team`))
    // Loadable components STOP

    return (
        <React.Fragment>
            {/* {(() => {
                return (
                    <React.Fragment>
                        This was rendered!
                    </React.Fragment>
                )
            })()} */}

            {/* !!! Children !!! */}
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col">

                        {/* <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#">Library</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Data</li>
                                </ol>
                            </nav>
                        </div> */}

                        { children }

                    </div>
                </div>
            </div>

            {/* Team */}
            {873 === nodeId && 
                <React.Fragment>
                    <Team />
                </React.Fragment>
            }

            {/* Harmonogram (Szkolenia) */}
            {871 === nodeId && 
                <React.Fragment>
                    <Harmonogram />
                </React.Fragment>
            }

            {/* Contact */}
            {5 === nodeId && 
                <React.Fragment>
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.6138153517677!2d19.923515616158642!3d50.05606527942285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b7483d44267%3A0x8c3eb47731cb486c!2sHeuresis+Sp.+Z+O.o.!5e0!3m2!1sen!2spl!4v1561407705962!5m2!1sen!2spl" width="600" height="450" frameborder="0" style={{border: 0}} allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="wp-block-soft4net-container-block container-fluid py-0">
                        <div className="wp-block-soft4net-container-block container bg-white">
                            <div className="row align-items-center">
                                <div className="col-xs-12 col-md-6 order-md-2">
                                    <h2>{translation.address[intl.locale]}</h2>
                                </div>
                                <div className="col-xs-12 col-md-6 order-md-1 d-none d-md-block">
                                    <h2>{translation.form_contact[intl.locale]}</h2>
                                </div>
                            </div>                        
                            <div className="row align-items-center">
                                <div className="col-xs-12 col-md-6 order-md-2">
                                    <div className="wp-block-soft4net-container-block container-fluid bg-orange mt-1 py-3">
                                        <UseDrupalBlockContentBasic blockId="1" />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 order-md-1">
                                    <h2 className="d-block d-md-none mt-5">{translation.form_contact[intl.locale]}</h2>
                                    <FormContactS4n />

                                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss START */}
                                    {/* <Accordion _defaultActiveKey={0}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={0}>
                                                <strong>Formularz kontaktowy</strong>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={0}>
                                                <Card.Body>
                                                    <FormContactS4n />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <div style={{display: `none`}} onClick={(e) => e.target.style.display = 'block'}>
                                        <FormContactS4n />
                                    </div>
                                    <div style={{visibility: `hidden`}} onClick={(e) => e.target.style.visibility = 'visible'}>
                                        <FormContactS4n />
                                    </div> */}
                                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss STOP */}

                                </div>
                            </div>                        
                        </div>
                    </div>
                </React.Fragment>
            }

            {/* {MenuUtils.isCurrentUri('internal:/blog') && 
                <Blog />
            } */}

            {/* {[1062, 1083].includes(nodeId) && 
                <React.Fragment>
                    <Shop />
                </React.Fragment>
            } */}

            {/* {<Location>
                {({ location }) => {
                    // console.log(JSON.stringify(location, null, 4))
                    return (
                        // @see: https://github.com/gatsbyjs/gatsby/issues/8787
                        <div className="container">
                            { '/pl#contact' === location.pathname + location.hash && <FormBasic /> }
                            { '/pl#contact' === location.pathname + location.hash && <FormBasicMaterialUi /> }
                        </div>
                    )
                }}
            </Location>} */}

        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(ComponentFunctional)