import GeneralUtils from './../utils/common'
import useWindowWidth from './useWindowWidth'

/**
 * @see: https://stackoverflow.com/questions/58608523/gatsby-react-conditional-rendering-based-on-window-innerwidth-misbehaving
 */
const useScreenSize = () => {
    return GeneralUtils.getScreenSize(useWindowWidth());
}

export default useScreenSize