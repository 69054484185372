import { useStaticQuery, graphql } from 'gatsby'

const useBuildTime = () => {
  const data = useStaticQuery(graphql`
    query Info {
      site {
        buildTime
      }
    }
  `)

  return data.site.buildTime
}

export default useBuildTime