import GeneralUtils from './../utils/common'
import useBuildTime from './useBuildTime'

/**
 * @see: https://stackoverflow.com/questions/58608523/gatsby-react-conditional-rendering-based-on-window-innerwidth-misbehaving
 */
const useVersion = (version = undefined) => {
    return GeneralUtils.getVersion(version, useBuildTime());
}

export default useVersion