/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Img from 'gatsby-image'
import Image from '../../image'

// s4n
import { injectIntl, Link } from "gatsby-plugin-intl"
import GeneralUtils from './../../../utils/common'
import ImageUtils from './../../../utils/gatsby/image'
import kebabCase from "lodash.kebabcase"
// import "./../../assets/sass/templates/node/_blog.scss"



const ComponentStateless = (props) => {
    const { intl, node, linkTranslation, bootstrapColClass } = props; // alias t === translate function
    const t = intl.formatMessage;
// console.log(`node: `, node);

    const disableImages = props.disableImages || false;
    const disableSummary = props.disableSummary || false;
    const disableButtonMore = props.disableButtonMore || false;
    const img = !disableImages && node?.relationships?.field_image_media?.relationships?.field_media_image?.localFile?.childImageSharp.fluid;
    
    let oFieldMediaImage = {};
    if (img) {
      const oDrupalMediaImageData = node.relationships.field_image_media.relationships.field_media_image.relationships.media__image.filter((media__image) => {
        // console.log(media__image); // {langcode: "pl", field_media_image: {…}}
        return intl.locale === media__image.langcode;
        // return `en` === media__image.langcode;
      });
      // console.log(oDrupalMediaImageData);
      // const { 0: oMediaImage } = oDrupalMediaImageData;
      // console.log(oMediaImage); // {langcode: "pl", field_media_image: {…}}
      // const { 0: { field_media_image: oFieldMediaImage } } = oDrupalMediaImageData;
      oFieldMediaImage = oDrupalMediaImageData && oDrupalMediaImageData.length ? oDrupalMediaImageData[0].field_media_image : {};
      // console.log(oFieldMediaImage);
    }

    return (
      <div key={`promoted-frontpage-${node.id}`} className="col-12 col-md-6 col-lg-3">
        <div className="blog-item animate-box">
          {!disableImages && <Link to={ node.path.alias } className="image">
            { img ? 
              <Img 
                fluid={ img }
                {...oFieldMediaImage}
              /> 
            : <Image /> }

            <span className="date">
              {/* {node.created} */}
              {GeneralUtils.dateFormat(node.created, null, intl.locale)}
            </span>
          </Link>}
          <div className="blog-content">
            <div className="prod-title">
              <h2><Link to={ node.path.alias }>{ node.title }</Link></h2>
              {!disableSummary && node.field_summary && <p dangerouslySetInnerHTML={{ __html: node.field_summary }}></p>}
              {/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div> */}
              {/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div> */}
              {!disableButtonMore && <Link to={ node.path.alias } className="btn btn-primary">
                { t({ id: "soft4net_more" }) }
              </Link>}
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default injectIntl(ComponentStateless)