// Find a decent currency library, or port commerceguys/intl to JavaScript. 😱

const currencySymbols = {
  USD: '$',
  GBP: '£',
  CAD: 'CA$',
  EUR: '€',
  PLN: 'zł',
};

const currencyFormat = {
  USD: '¤#',
  GBP: '#¤',
  CAD: '¤#',
  EUR: '# ¤',
  PLN: '# ¤',
};

export function formatCurrency(currencyCode, number, locale = null) {
  const options = {
    style: 'currency',
    currency: currencyCode,
    // maximumSignificantDigits: 2,
  }
  return number.toLocaleString(locale, options);

  let formatted = currencyFormat[currencyCode].replace('¤', currencySymbols[currencyCode]);
  formatted = formatted.replace('#', parseFloat(number).toFixed(2).toLocaleString(locale));
  return formatted;
}
