import gql from 'graphql-tag';

// @see: https://graphql-search-api.readthedocs.io/en/latest/
// @see: https://graphql-search-api.readthedocs.io/en/latest/search-parameters/
export default (filters) => gql`
{
    searchAPISearch(
        index_id: "${filters.index_id}",
        language: "${filters.intl.locale}",
        fulltext: {
            keys: "${filters.query}",
            # A list of fields that the fulltext search is to be performed on. If left blank the search will be done on all fulltext fields.
            # fields: ["title", "summary_processed", "processed"],
            conjunction: "OR",
        },
        # sort: {
        #     field: "field_date_from",
        #     value: "desc"
        # },
        # sort: {
        #     field: "title",
        #     value: "asc"
        # },
        # range: {
        #     start: 0,
        #     end: 2
        # },
        # conditions: {
        #     operator: "=",
        #     name: "title",
        #     value: "Aplikacje"
        # },
        # facets: {
        #     field: "fields_tags",
        #     limit: 3,
        #     operator: "=",
        #     min_count: 1,
        #     missing: false,
        # }
    ) {
        result_count
        documents {
            # index_id
            # ... on DefaultIndexDoc {
            #     nid
            #     title
            #     field_tags
            #     # rendered_item
            # }
            ... on TestContentIndexDoc {
                nid
                url
                title
                field_date_from
                field_date_to
                # field_tags
            }
        }
        # facets {
        #     name
        #     values {
        #         count
        #         filter
        #     }
        # }
    }
}  
`