import React from "react"
import { connect } from 'react-redux'
import { injectIntl } from "gatsby-plugin-intl"
import { changeFontSize } from './../../../../s4n/state/actions/fontSize'
import classNames from 'classnames';

export const fontSize = [`small`, `normal`, `large`];

/**
 * @see: https://stackoverflow.com/questions/40995948/how-to-add-a-dynamic-class-to-body-tag-in-gatsby-js
 * 
 * @param {*} props 
 */
const ComponentStateless = (props) => {
    const { intl, sFontSize } = props;
    const t = intl.formatMessage;
    const fontSize = [`small`, `normal`, `large`];

    return (
        <React.Fragment>
            <div className="accessibility-font-size">
                {fontSize.map((size, index) => {
                    const oClassNames = classNames({
                        [`text-size--${size}`]: true,
                        [`selected`]: sFontSize === size,
                    });

                    return (
                        <a onClick={() => props.changeFontSize(size)}
                            _tabindex="0" 
                            title={ t({ id: `soft4net_accessibility_font_size_${size}` }) } 
                            aria-label={ t({ id: `soft4net_accessibility_font_size_${size}` }) } 
                            className={oClassNames}>
                            <span aria-hidden="true">A</span>
                        </a>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    sFontSize: state.fontSize.fontSize,
})

const mapDispatchToProps = dispatch => ({
    changeFontSize: (fontSize) => dispatch(changeFontSize(fontSize)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ComponentStateless));