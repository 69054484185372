// import _ from 'lodash';
// import useAllFileFile from './../../hooks/useAllFileFile'

import { client } from './../../../../apollo/client';
import graphqlQuery from './../../../../model/apollo/search';
import GeneralUtils from './../../../../utils/common'
import { CommonIndexDocument } from './../interface';

// Document contains indexed fields 
interface SearchAPISearchIndexDocument {
    rendered_item?: string,
    summary_processed?: string, // body:summary_processed
    processed?: string, // body:processed
    status?: boolean,
    uid?: number,

    title: string,
    nid: number,
    url: string,
    field_date_from: Array<number>,
    field_date_to: Array<number>,
}

const SEARCH_MIN_QUERY_LENGTH: number = 3;

class SearchBackend {

    // @see: https://stackoverflow.com/questions/32647215/declaring-static-constants-in-es6-classes
    // But unfortunately this class property syntax is only in an ES7 proposal, and even then it won't allow for adding const to the property.
    // static const SEARCH_MIN_QUERY_LENGTH = 3;
    static get SEARCH_MIN_QUERY_LENGTH():number {
        return SEARCH_MIN_QUERY_LENGTH;
    }

    static result_count: number = 0;

    static setSiteSearchIndex = (siteSearchIndex) => {
    }

    // @todo: refactor to backend.js
    static executeQuery = async (query: string, intl: any) => {

        const oFilters = {
            index_id: 'test_content_index', // index_id: 'default_index',
            intl,
            query,
        };

        const APOLLO_QUERY = graphqlQuery(oFilters);

        // let queryResults = null;

        // client
        //     .query({
        //         query: APOLLO_QUERY
        //     })
        //     .then(({ data, loading, error }) => {
        //         if (loading) return <p>Loading...</p>;
        //         if (error) return <p>Error: ${error.message}</p>;

        //         const queryResults = data.searchAPISearch;
        //         // console.log(queryResults)

        //         // return queryResults
        //         this.setState({
        //             // query: query,
        //             results: queryResults
        //         })
        //     })
        //     .catch(error => console.error(error))

        // try {
/*
            export declare type ApolloQueryResult<T> = {
                data: T;
                errors?: ReadonlyArray<GraphQLError>;
                loading: boolean;
                networkStatus: NetworkStatus;
                stale: boolean;
            };
*/
            const { data, errors, loading } = await client.query({query: APOLLO_QUERY});
        // } catch (error) {
        //     console.log(error)
        // }

        if (errors) {
            return false;
        }

        // if (loading) {
        //     return <p>Loading...</p>
        // }

        const queryResults = data.searchAPISearch;

        // console.log(queryResults)
        return queryResults;
    }

    static getAgregateResults = (aDataCollectionTranslated: Array<any>) => {
        if (SearchBackend.getResultsCount() <= 0) {
            return aDataCollectionTranslated;
        }
/*
        let mapAgregatedResults = new Map();
        (SearchBackend.getResultsCount() > 0) && aDataCollectionTranslated.map(({ node: nodeIndexFields }) => {
        // (SearchBackend.getResultsCount() > 0) && aDataCollectionTranslated.map(nodeIndexFields => {
            // console.log(nodeIndexFields)
            if (nodeIndexFields) {
                if (typeof mapAgregatedResults.get(nodeIndexFields.title) === `undefined`) {
                    mapAgregatedResults.set(nodeIndexFields.title, []);
                }
                mapAgregatedResults.get(nodeIndexFields.title).push(nodeIndexFields);
            }
        });
*/
        let oAgregatedResults = {};
        aDataCollectionTranslated.forEach(({ node: nodeIndexFields }) => {
            if (`undefined` === typeof oAgregatedResults[nodeIndexFields.title]) {
                oAgregatedResults[nodeIndexFields.title] = [];
            }
            oAgregatedResults[nodeIndexFields.title].push(nodeIndexFields);
        });

        // console.log(oAgregatedResults);

        return oAgregatedResults;

        // const aAgregatedResults = Object.keys(oAgregatedResults);
        // return aAgregatedResults;
    }

    // @todo: refactor to backend.js
    static getResults = async (query: string, intl: any) => {
        
        // const queryResults = await this.executeQuery(query, intl); // 'this' cannot be referenced in a static property initializer.ts(2334)
        const queryResults = await SearchBackend.executeQuery(query, intl);

        SearchBackend.result_count = queryResults.result_count;
        const documents: Array<SearchAPISearchIndexDocument> = queryResults.documents ? queryResults.documents : [];

        const documentsFiltered = documents.filter(nodeIndexFields => {
            return `SOFT4NET_DO_NOT_DELETE` !== nodeIndexFields.title;
        })

        // const aDocumentsWrappedWithNodeField = documents.map(nodeIndexFields => ({ node: nodeIndexFields }))
        const aDocumentsWrappedWithNodeField = documentsFiltered.map(nodeIndexFields => {

            // Build harmonogram dates from - to
            let date: string = '';
            if (nodeIndexFields.field_date_from.length && nodeIndexFields.field_date_to.length) {
                let dateFrom: number = nodeIndexFields.field_date_from[0]; // seconds
                let dateTo: number = nodeIndexFields.field_date_to[nodeIndexFields.field_date_to.length - 1]; // seconds

                // Convert to miliseconds for new Date()
                dateFrom = dateFrom * 1000; 
                dateTo = dateTo * 1000;

                // date = `${GeneralUtils.dateFormat(dateFrom, null, intl.locale)} - ${GeneralUtils.dateFormat(dateTo, null, intl.locale)}, ${ t({ id: "soft4net_node_harmonogram_lectures" }) }: ${nodeIndexFields.field_date_from.length}`;
                date = `${GeneralUtils.dateFormat(dateFrom, null, intl.locale)} - ${GeneralUtils.dateFormat(dateTo, null, intl.locale)}`;
            }
            
            const { nid, title } = nodeIndexFields;

            const fields: CommonIndexDocument = {
                nid,
                title,
                langcode: intl.locale,
                path: {
                    /**
                     * We get url from SearchAPI!
                     * We replace it to match gatsby router and gatsby-plugin-intl
                     * /pl/nasza-oferta/klienci-biznesowi/programy-rozwojowe-dla-firm => /nasza-oferta/klienci-biznesowi/programy-rozwojowe-dla-firm
                     */
                    alias: nodeIndexFields.url.replace('/' + intl.locale, ''),
                    langcode: intl.locale,
                },
                date,
            };
            
            return { node: fields };
        });

        // Use for static search!!!
        // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
        // const aDataCollectionTranslated = CollectionDataUtils.filterData(aDocumentsWrappedWithNodeField, intl.locale, true, 'uniqueDrupalInternalId');
        const aDataCollectionTranslated = aDocumentsWrappedWithNodeField;

        return SearchBackend.getAgregateResults(aDataCollectionTranslated);
    }

    static getResultsCount = ():number => {
        return SearchBackend.result_count;
    }

}

export default SearchBackend;