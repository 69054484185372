import React from 'react';
import { FormattedMessage } from "gatsby-plugin-intl"
import * as Yup from 'yup';

const YupSetLocale = {
    mixed: {
        default: <FormattedMessage id="soft4net_form_validation_message_mixed_default" />,
        required: <FormattedMessage id="soft4net_form_validation_message_mixed_required" />,
    },
    string: {
        default: <FormattedMessage id="soft4net_form_validation_message_string_default" />,
        required: <FormattedMessage id="soft4net_form_validation_message_string_required" />,
        email: <FormattedMessage id="soft4net_form_validation_message_string_email" />,
        min: <FormattedMessage id="soft4net_form_validation_message_string_min" />, // this should contain "Lorem ipsum ${min} dolor"
        max: <FormattedMessage id="soft4net_form_validation_message_string_max" />, // this should contain "Lorem ipsum ${max} dolor"
    },
    number: {
        // default: <FormattedMessage id="soft4net_form_validation_message_number_default" />,
        // typeError: <FormattedMessage id="soft4net_form_validation_message_number_type_error" />,
        integer: <FormattedMessage id="soft4net_form_validation_message_number_integer" />,
        positive: <FormattedMessage id="soft4net_form_validation_message_number_positive" />,
        min: <FormattedMessage id="soft4net_form_validation_message_number_min" />, // this should contain "Lorem ipsum ${min} dolor"
        max: <FormattedMessage id="soft4net_form_validation_message_number_max" />, // this should contain "Lorem ipsum ${max} dolor"
    },
};

Yup.setLocale(YupSetLocale);

export default Yup;