import { useStaticQuery, graphql } from 'gatsby'
import FragmentBlogIndex from './../../../../model/fragments/allNodeBlog/blog-index'

export default () => {
    const data = useStaticQuery(graphql`
    query {

        indexPL: allNodeBlog (
            limit: 5,
            filter: {
                promote: {
                    eq: true
                }
                langcode: {
                    eq: "pl"
                }
            }
            sort: {
                fields: [created]
                order: DESC
            }
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }

        indexEN: allNodeBlog (
            limit: 5,
            filter: {
                promote: {
                    eq: true
                }
                langcode: {
                    eq: "en"
                }
            }
            sort: {
                fields: [created]
                order: DESC
            }
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }

        # indexDE: allNodeBlog (
        #     limit: 5,
        #     filter: {
        #         promote: {
        #             eq: true
        #         }
        #         langcode: {
        #             eq: "de"
        #         }
        #     }
        #     sort: {
        #         fields: [created]
        #         order: DESC
        #     }
        # ) {
        #     edges {
        #         node {
        #             ...FragmentBlogIndex
        #         }
        #     }
        # }

        # indexES: allNodeBlog (
        #     limit: 5,
        #     filter: {
        #         promote: {
        #             eq: true
        #         }
        #         langcode: {
        #             eq: "es"
        #         }
        #     }
        #     sort: {
        #         fields: [created]
        #         order: DESC
        #     }
        # ) {
        #     edges {
        #         node {
        #             ...FragmentBlogIndex
        #         }
        #     }
        # }

        # allNodePage (
        #     filter: {
        #         promote: {
        #             eq: true
        #         }
        #     }
        #     sort: {
        #         fields: [created]
        #         order: DESC
        #     }
        # ) {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__nid
        #             internal {
        #                 type
        #             }

        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #             field_summary
        #         }
        #     }
        # }

        # allNodeArticle {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__nid
        #             internal {
        #                 type
        #             }                    
        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #         }
        #     }
        # }

        # allCommerceProductDefault {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__product_id
        #             internal {
        #                 type
        #             }
        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #         }
        #     }
        # }

    }
    `)

    return data;
}
