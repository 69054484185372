// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// s4n
import { injectIntl, Link } from "gatsby-plugin-intl"
import LinkS4n from './s4n/Link'
import Menu from "./s4n/Menu/menu"
import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
import Logo from './../images/custom/heuresis-logo.svg'
import LogoWhite from './../images/custom/heuresis-logo-white.svg'
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import EULogo from './../images/custom/eu-logo.svg'


import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
// import SignIn from './SignIn/SignIn';
// import LogoutLink from './LogoutLink/LogoutLink';

// import AccessibilityHighContrast from './s4n/Accessibility/HighContrast'
// import AccessibilityFontSize from './s4n/Accessibility/FontSize'

// Shop START
import { connect } from 'react-redux'
import { cartFlyoutOpen } from './s4n/Shop/ext/actions'
const openFlyout = (dispatch) => dispatch(cartFlyoutOpen());
// Shop STOP


// ES6
//import Webform from 'hn-react-webform';

const Header = (props) => {
    const { siteTitle, intl } = props;
    const t = intl.formatMessage;



    // Shop START
    const {
      dispatch,
      // cart: { carts, itemCount },
      // cartFlyout: { open }
    } = props;
    // Shop STOP



    // console.log(props.intl.locale)
    // console.log(props.userAuthenticated)

    return (
      // <div className={cssModule.root}>
        // <header className="header">
        //   <div className="container">
        //     <div className="row">
        //       <div className="col-2">
        //         <h1 style={{ margin: 0 }}>
        //           <Link to="/">
        //             {/* {siteTitle} */}
        //             <img src={Logo} alt={siteTitle} className="logo" />
        //             {/* <ImageS4n src="custom/heuresis-logo.svg" alt={siteTitle} /> */}
        //             {/* <ImageS4n src="gatsby-astronaut.png" alt={siteTitle} className="img-responsive" /> */}
        //           </Link>
        //         </h1>
        //       </div>
        //       <div className="col-10">
        //         <Menu type="main" />
        //       </div>
        //     </div>
        //   </div>
        // </header>
      // </div>

        // This div.sticky-wrapper is fixture for error:
        // Uncaught DOMException: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
        // <div className="sticky-wrapper" _style="height: 99.9844px;">

          <header className="header stuck">
            <div className="container">

              <nav className="navbar navbar-expand-lg navbar-light ___bg-light">
                <Link to="/" className="navbar-brand flex-shrink-0">
                  <img src={props.isHighContrastMode ? LogoWhite : Logo} alt={siteTitle} className="logo" />
                </Link>

                <button className="navbar-toggler order-2 order-lg-1" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                  {/* <div className="container p-0"> */}

                    <div className="row">
                      <div className="col">
                        <i className="fas fa-phone-square-alt"></i> <a href="tel:+48122940462">+48 12 294 04 62</a>
                        {/* <i className="fas fa-envelope"></i> <a href="mailto:biuro@heuresis.pl">biuro@heuresis.pl</a> */}
                        <i className="fas fa-envelope"></i> <a data-encoded="ovheb@urherfvf.cy" href="mailto:">ovheb@urherfvf.cy</a>
                      </div>                  
                      <div className="col">
                        <ul>
                          <li><a href="https://www.facebook.com/Heuresis1/" target="_blank" rel="noreferrer" className="facebook" title="Facebook"><i className="fab fa-facebook"></i></a></li>
                          {/* <li><a href="https://twitter.com/heuresis_pl" target="_blank" rel="noreferrer" className="twitter" title="Twitter"><i className="fab fa-twitter"></i></a></li> */}
                          <li><a href="https://www.linkedin.com/company/heuresis-certified-management-trainers-and-consultants-ltd./" target="_blank" rel="noreferrer" className="linkedin" title="LinkedIn"><i className="fab fa-linkedin"></i></a></li>
                        </ul>
                      </div>
                      <div className="col search">
                        <Search />
                      </div>

                      {`true` === process.env.SOFT4NET_SHOP && <div className="col shopping-cart">
                        <a href="#" className="btn" onClick={() => openFlyout(dispatch)} title={ t({ id: "soft4net_shop_cart_flyout_header" }) }>
                          <i className="fas fa-shopping-cart"></i>
                          {/* {` `}
                          { t({ id: "soft4net_shop_cart_flyout_header" }) } */}
                        </a>
                      </div>}

                      {/* {`true` === process.env.SOFT4NET_ACCESSIBILITY && 
                        <div className="col accessibility">
                          <AccessibilityHighContrast />
                          <AccessibilityFontSize />
                        </div>
                      } */}

                    </div>
                    <div className="row">
                      <div className="col">
                        <Menu type="main" enableLanguage={true} />
                      </div>
                    </div>
                    
                  {/* </div> */}

                  {/* <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit"><i className="fas fa-search"></i></button>
                  </form> */}

                  {/* {props.userAuthenticated ? <LogoutLink /> : <SignIn />} */}

                </div>

                <LinkS4n to={1300} className="d-flex align-items-center pl-5 order-1 order-lg-2">
                  <img src={EULogo} width="61" height="55" alt={siteTitle} className="logo" />
                </LinkS4n>
              </nav>



              {false && <nav className="navbar navbar-expand-lg navbar-light ___bg-light">

                {/* <a className="navbar-brand" href="#">Navbar</a> */}
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt={siteTitle} className="logo" />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Dropdown
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Something else here</a>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link disabled" href="#">Disabled</a>
                    </li>
                  </ul>

                  <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                  </form>

                </div>
              </nav>}



              {/* <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#link">Link</Nav.Link>
                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                  </Form>
                </Navbar.Collapse>
              </Navbar> */}






            </div>
          </header>

        // </div>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



// export default injectIntl(Header)
const HeaderWrapped = injectIntl(withDrupalOauthConsumer(Header))

// Shop START
// const mapStateToProps = ({ cart, cartFlyout }) => ({ cart, cartFlyout });
const mapStateToProps = state => {
  return { 
    cart:       state.shopReducers.cart,
    cartFlyout: state.shopReducers.cartFlyout,
    isHighContrastMode: state.highContrast.isHighContrastMode,
  }
};
export default connect(mapStateToProps)(HeaderWrapped);
// Shop STOP



// export default withStyles(styles)(withDrupalOauthConsumer(Navigation));