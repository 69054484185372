import drupalOauth from './drupalOauth'



const SOFT4NET_DRUPAL_OAUTH_CLIENT_ID = `b6a04cc0-9203-44c2-81d0-9a919820788f`
const SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET = 'vHsp;2<4ST~p`w)Y{Agc?^`{R}m2#{3F'

// Initialize a new drupalOauth client which we can use to seed the context
// provider.
const drupalOauthClient = new drupalOauth({
    drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
    client_id: SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
    client_secret: SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})

// console.log(drupalOauthClient)

export default drupalOauthClient