/**
 * Map() implemented because when usgin Object order was not preserved!!!
 * @see: https://stackoverflow.com/questions/17696473/how-to-keep-the-sequence-in-javascript-map
 */
// import _ from "lodash"



class CollectionData {
    /**
     * Prepare data collection without duplications in one language
     * because of Drupal translation fallback strategy if translation does not exists!
     * In EE language fallback node has both node.langcode === 'pl' and node.path.langcode === 'pl' it takes langcode from origin language source!!!
     */
    static filterData(edges, locale, blTransformToMapableArray = true, key = 'drupal_internal__nid')
    {
        let mapDataCollectionTranslated = new Map();

        edges.forEach(edge => {
            const { node } = edge;
            if (locale === node.langcode 
                && mapDataCollectionTranslated.get(node[key]) === undefined // key was not found in Map() so we can add new

                && 'SOFT4NET_DO_NOT_DELETE' !== node.title

            ) {
                mapDataCollectionTranslated.set(node[key], edge);
            }
        });

        // !!! THIS DOES NOT PRESERVE ORDER IN CASE YOU USED SORT IN GRAPHQL !!!
        if (!blTransformToMapableArray) {
            // @see: https://gist.github.com/lukehorvat/133e2293ba6ae96a35ba
            let oDataCollectionTranslated = {};
            for (let [key, value] of mapDataCollectionTranslated) {
              oDataCollectionTranslated[key] = value;
            }

            return oDataCollectionTranslated;
        }

        return CollectionData.transformToMapableArray(mapDataCollectionTranslated);
    }

    static transformToMapableArray(mapDataCollectionTranslated)
    {
        let aDataCollectionTranslated = [];

        mapDataCollectionTranslated.forEach((value, key) => {
            const edge = mapDataCollectionTranslated.get(key);
            aDataCollectionTranslated.push(edge);
        });

        return aDataCollectionTranslated;
    }
}

export default CollectionData