import { useStaticQuery, graphql } from 'gatsby'
import FragmentProductIndex from './../../../fragments/allCommerceProductDefault/product-index'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            index: allCommerceProductDefault {
                edges {
                    node {
                        ...FragmentProductIndex
                    }
                }
            }
        }
    `)

    return data;
}