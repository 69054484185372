// import { Link } from "gatsby"
import React from "react"

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import loadable from '@loadable/component'
import Preloader from './s4n/Preloader'
import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
import GeneralUtils from './../utils/common'
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./s4n/Menu/utils"

import LogoWhite from './../images/custom/heuresis-logo-white.svg'
// import { ReactComponent as MapPoland } from './../images/custom/POL_location_map.svg'

import PromotedFrontPage from './s4n/Promoted/FrontPage'



const Footer = (props) => {
    const { intl } = props;
    const t = intl.formatMessage;

    const translation = {
        soft4net_project_realization: {
            pl: `Projekt i realizacja - Soft4Net`,
            en: `Project and realization - Soft4Net`,
            de: `Projekt und Realisierung - Soft4Net`,
            es: `Proyecto y realización - Soft4Net`,
        },
        soft4net_project_realization_anchor_title: {
            pl: `tworzenie stron www, sklepy internetowe, system do rezerwacji`,
            en: `web pages, ecommerce, booking`,
            de: `Webseiten, E-Commerce, Buchung`,
            es: `páginas web, comercio electrónico, reservas`,
        },
        news_at_page: {
            pl: 'Nowości na stronie',
            en: 'News at page',
            de: `Neuigkeiten auf Seite`,
            es: `Noticias en la página`,
        },
        schedule_map_title: {
            pl: 'Sprawdź szkolenie w Twoim mieście:',
            en: 'Check the training in your city:',
            de: `Überprüfen Sie die Ausbildung in Ihrer Stadt:`,
            es: `Consulta la formación en tu ciudad:`,
        }
    }

    // const nodeNews = MenuUtils.getItemByNodeId(866, intl.locale);
    const nodeHarmonogram = MenuUtils.getItemByNodeId(871, intl.locale);
    const nodeContact = MenuUtils.getItemByNodeId(5, intl.locale);
    
    const buildTime = useBuildTime();

    const SvgMapPoland = loadable(() => import(`./svg/map-poland`), {fallback: <Preloader />})
    const FormHeuresisNewsletter = loadable(() => import(`./../components/forms/webform/heuresis_newsletter`), {fallback: <Preloader />})

    const activeCities = [
        // {
        //     name: `Gdańsk`,
        //     cx: "275", 
        //     cy: "64",
        // },
        // {
        //     name: `Katowice`,
        //     cx: "300",
        //     cy: "450",
        // },
        {
            name: `Kraków`,
            cx: "355",
            cy: "480",
        },
        // {
        //     name: `Lublin`,
        //     cx: "510",
        //     cy: "370",
        // },
        // {
        //     name: `Wrocław`,
        //     cx: "180", 
        //     cy: "370",
        // },
    ]; 

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <Menu type="footer" className="menu-footer" _disableDropdown={true} disableLanguage={true} nodeLinkUriRoot="entity:node/1058" />
                            <UseDrupalBlockContentBasic blockId="71" />
                            <Link to={893} className="btn btn-secondary" role="button"></Link>
                            {/* <Link to="/ocen-nas#webform_quality_check_s4n" className="btn btn-secondary goto-scroll" role="button">ocen-nas</Link> */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            {/* <h4>{nodeNews ? nodeNews.title : null}</h4> */}
                            <h4>{translation.news_at_page[intl.locale]}</h4>
                            
                            <PromotedFrontPage limit={5} disableImages={true} disableSummary={true} disableButtonMore={true} component={`ul`} />

                            {/* <a className="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                Link with href
                            </a>
                            <div className="collapse" id="collapseExample">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            </div> */}

                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            {nodeHarmonogram ?
                                <React.Fragment>
                                    {/* <h4 className="mb-3">{nodeHarmonogram ? nodeHarmonogram.title : null}</h4> */}
                                    <h4 className="mb-3"><a href={nodeHarmonogram?.link?.url}>{nodeHarmonogram ? nodeHarmonogram.title : null}</a></h4>
                                    {activeCities.length ?
                                        <React.Fragment>
                                            <span className="d-block">{translation.schedule_map_title[intl.locale]}</span>
                                            <SvgMapPoland id="svg-map-poland" harmonogramUrl={nodeHarmonogram.link.url} activeCities={activeCities} />

                                            <div className="row regions">
                                                {activeCities.map((city, index) => {
                                                    return <div className="col-6 _col-md-6 col-lg-4"><Link to={`${nodeHarmonogram.link.url}?city=${city.name}`}>{city.name}</Link></div>
                                                })}
                                            </div>
                                        </React.Fragment>
                                        : null 
                                    }
                                </React.Fragment>
                                : null
                            }
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            {/* <h4 className="mb-3">{nodeContact ? nodeContact.title : null}</h4> */}
                            <h4 className="mb-3"><a href={nodeContact?.link?.url}>{nodeContact ? nodeContact.title : null}</a></h4>
                            <UseDrupalBlockContentBasic blockId="1" />
                            {/* <div className="col" dangerouslySetInnerHTML={{ __html: useDrupalBlockContentBasic_Soft4NetFooterContactDetails.body.value }}></div> */}
                            <h4>Newsletter</h4>
                            <FormHeuresisNewsletter />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 _col-md-6 text-center">
                            <img src={LogoWhite} alt="" className="logo" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div id="copyright">
                                <p>
                                    Copyright &copy; {new Date().getFullYear()} { t({ id: "soft4net_all_rights_reserved" }) }
                                </p>
                                <p>
                                    <a 
                                        href="https://www.soft4net.pl/" 
                                        // title={ t({ id: "soft4net_project_realization_anchor_title" }) } 
                                        title={ translation.soft4net_project_realization_anchor_title[intl.locale] } 
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        {/* { t({ id: "soft4net_project_realization" }) } */}
                                        { translation.soft4net_project_realization[intl.locale] }
                                    </a>
                                </p>
                                {`true` === process.env.SOFT4NET_DISPLAY_BUILD_TIME_IN_FOOTER && 
                                    <p className="mt-5">
                                        { t({ id: "soft4net_deploy_build_time" }) }: {GeneralUtils.dateFormat(buildTime, "LLL", intl.locale)}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default injectIntl(Footer)
