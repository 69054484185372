import React from "react"
//import { Link } from "gatsby"
import Img from 'gatsby-image'
import Image from "../../../image"

// s4n
// import "./../assets/sass/pages/_blog.scss"
import { injectIntl, Link } from "gatsby-plugin-intl"
import executeQuery from "./query"
import GeneralUtils from './../../../../utils/common'
import CollectionDataUtils from "./../../../../utils/Collection/data"
import BlogCard from './../../Blog/card'



const ComponentStateless = (props) => {

  const { intl } = props
  const t = intl.formatMessage
  const data = executeQuery();

  // mege all results keys into one array with main key => "index" so we can pass this to CollectionDataUtils.filterData
  // const data = { index: { edges: [] } };
  // Object.keys(dataToConvert).map(allNodeType => {
  //   const allNodeTypeEdges = dataToConvert[allNodeType].edges;
  //   allNodeTypeEdges.map(edge => {
  //     data.index.edges.push(edge);
  //   });
  // });
  // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
  // const aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, intl.locale);

  // let aDataCollectionTranslated = null;
  // if (`pl` === intl.locale) {
  //   aDataCollectionTranslated = data.indexPL.edges;
  // }
  // if (`en` === intl.locale) {
  //   aDataCollectionTranslated = data.indexEN.edges;
  // }
  let aDataCollectionTranslated = data?.[`index${intl.locale.toUpperCase()}`].edges;

  // console.log(JSON.stringify(dataToConvert, null, 4))
  // console.log(JSON.stringify(data.index.edges, null, 4))
  // console.log(JSON.stringify(aDataCollectionTranslated, null, 4))

  const { limit } = props;

  const disableImages = props.disableImages || false;
  const disableSummary = props.disableSummary || false;
  const disableButtonMore = props.disableButtonMore || false;

  return (
    <React.Fragment>
      <section className="blog">
        <div className="___container">
          <div className="row">

            {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
            {aDataCollectionTranslated.map(({ node }, index) => {

              if (index >= limit) {
                return null;
              }

              if (`ul` === props.component) {
                return (
                  <div className={`col-12 col-md-6 col-lg-3    justify-content-start ${0 === index ? `pb-2` : `py-2`}`}>
                    <Link to={ node.path.alias }>{ node.title }</Link>
                  </div>
                )
              }

              return <BlogCard node={node} disableImages={disableImages} disableSummary={disableSummary} disableButtonMore={disableButtonMore} />
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default injectIntl(ComponentStateless)