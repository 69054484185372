/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"

// s4n
import $ from "jquery"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'
// import * as loadScript from 'simple-load-script'
// import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
// import YupSetLocale from './../components/forms/yup-setlocale';



import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'
import drupalOauthClient from './drupal-oauth/GetDrupalOauthClient.s4n'

import { connect } from 'react-redux'

// SHOP EXT START
// // import React, { Component } from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import { Route, Switch } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
// import configureStore, { history } from './../components/s4n/Shop/ext/utils/configureStore';
// // import { graphqlClient } from './utils/api';

// // import Header from './blocks/Header';
// // import CatalogMenu from './blocks/CatalogMenu'
// // import Footer from './blocks/Footer'
// // import Home from './pages/Home';
// import Cart from './../components/s4n/Shop/ext/pages/Cart';
// // import Catalog from './pages/Catalog';
// // import Product from './pages/Product';
// import { cartFetch, setCartToken } from './../components/s4n/Shop/ext/actions'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// // import Checkout from './pages/Checkout'

//     // @see: https://github.com/gatsbyjs/gatsby/issues/14480
//     /**
//      * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
//      * because render is called before useEffect()!
//      */
//     // const store = configureStore();
//     // // if (typeof window !== 'undefined') {
//     //   store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
//     //   // store.dispatch(setCartToken('ajg6t2g3kz'));
//     //   store.dispatch(cartFetch());
//     // // }
// SHOP EXT STOP

// s4n
import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'
import { fontSize as accessibilityFontSize } from './s4n/Accessibility/FontSize/index'

import ServiceFacebookBadge from './s4n/Service/facebookBadge'



const Layout = (props) => {

    const { children, intl } = props
    const t = intl.formatMessage;

    const [context, setContext] = useState({
      // loadedScripts: false,
      isHeroSlider: false,
      windowLoaded: false,
    });

    // const currentBuildVersion = useVersion();

    // SHOP EXT START
    // @see: https://github.com/gatsbyjs/gatsby/issues/14480
    /**
     * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
     * because render is called before useEffect()!
     */
    // const store = configureStore();
    // // if (typeof window !== 'undefined') {
    //   // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
    //   store.dispatch(setCartToken('9jg6t2g3kz'));
    //   store.dispatch(cartFetch());
    // // }
    // SHOP EXT STOP

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {

      const isBrowser = typeof window !== 'undefined'
      if (!isBrowser) {
          return false
      }

      // console.log('useEffect')



      // SHOP EXT START
      // @see: https://github.com/gatsbyjs/gatsby/issues/14480
      // const store = configureStore();
      // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
      // store.dispatch(cartFetch());
      // SHOP EXT STOP



      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

      const $isHeroSlider = $('.wp-block-gallery.hero')
      if ($isHeroSlider.length) {
        setContext({
          isHeroSlider: true,
        })
      }

    }, []);

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    // const useDrupalBlockContentBasic_Soft4NetFooterContactDetails = useDrupalBlockContentBasic(1);
    // console.log(useDrupalBlockContentBasic_Soft4NetFooterContactDetails);

    //console.log(useDrupalInterfaceTranslation('@items enabled', {'@items': 12}))
    //console.log(useDrupalInterfaceTranslation('soft4cms_all_rights_reserved'));

    // console.log(props.location)

    const { node } = props;
    let nodeId = null;
    let productId = null;
    let WrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      WrapperMainClassNode = {
        [node.internal.type]: true,
        [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
      }
    }

    // console.log(node)

    const oClassNamesHtml = classNames({
      [`accessibility-high-contrast`]: props.isHighContrastMode,
      [`accessibility-font-size`]: accessibilityFontSize.includes(props.fontSize),
      [`accessibility-font-size-${props.fontSize}`]: accessibilityFontSize.includes(props.fontSize),
    });

    const oClassNamesBody = classNames({
      [`${useScreenSize()}`]: true,
    });

    const WrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      // hasHeroSlider: context.isHeroSlider,
      // [data.site.siteMetadata.title]: true,
      ...WrapperMainClassNode,
    });

    // const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'))
    // const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'))
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'))

    return (

      // <Provider store={store}>
      //   <ConnectedRouter history={history}>
      <React.Fragment>

          <Helmet
              defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
              htmlAttributes={{
                  class: oClassNamesHtml
              }}
              bodyAttributes={{
                  class: oClassNamesBody
              }}
          >
            {/* <!-- Global site tag (gtag.js) - Google Ads: 352632305 --> */}
            {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-352632305"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-352632305');
              `}
            </script> */}

            {/* <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="af5f8a63-5a51-4b73-93f3-6e6b0d492891" data-blockingmode="auto" type="text/javascript"></script> */}
          </Helmet>

          <div 
            // className={cssModule.root}
            className={WrapperMainClass}
          >

            <Header siteTitle={data.site.siteMetadata.title} />

            <CartFlyout />

            {/* <YupSetLocale /> */}

            <main className="main">
              <S4nLayoutBlock name="main" nodeId={nodeId} productId={productId} >
                {children}
              </S4nLayoutBlock>
            </main>

            <Footer />

            {/* <ServiceCookieCompliance /> */}
            {/* <ServiceTawkTo serviceId="serviceId" /> */}
            <ServiceFacebookBadge serviceId="https://www.facebook.com/Heuresis1/" serviceName="Heuresis.pl" />
            <ServiceScrollTop />

          </div>

        </React.Fragment>

      //   </ConnectedRouter>
      // </Provider>

    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutWrapped = withDrupalOauthProvider(drupalOauthClient, injectIntl(Layout))

const mapStateToProps = state => ({
  fontSize: state.fontSize.fontSize,
  isHighContrastMode: state.highContrast.isHighContrastMode,
})

export default connect(mapStateToProps)(LayoutWrapped);