import React from "react"

// @see: https://stackoverflow.com/questions/53371356/how-can-i-use-react-hooks-in-react-classic-class-component
export default (ToComponent, AsProp, queryCallback) => {
    return function WrappedComponent(props) {

        // @see: https://stackoverflow.com/questions/40868189/how-to-create-a-dynamic-prop-name-in-react
        let dynamicProps = {};
        dynamicProps[AsProp] = queryCallback();

        return <ToComponent {...props} {...dynamicProps} />;
    }
}